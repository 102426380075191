$project:"db";
@import "./styles/reset.scss";
@import "./styles/config/colors.scss";
@import "./styles/config/devices.scss";
@import "./styles/config/fonts.scss";
@import "./styles/config/form.scss";
html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  overscroll-behavior-y: contain;
}

a {
  text-decoration: none;
}

input {
  border-radius: 0;
}

// html {
//   @include phone {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//   }
// }
