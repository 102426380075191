$font-path: "../../assets/fonts";

@mixin font-face($font-family) {
  @font-face {
    font-family: "#{$font-family}";
    src: url("#{$font-path}/#{$font-family}.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
}

$font-didot-medium-ital: "Montserrat";
$font-didot-medium: "Montserrat";
$font-didot-light-ital: "Montserrat";
$font-didot-light: "Montserrat";
$font-futura-medium: "Futura-Medium";
$font-futura-book: "Futura-Book";
$font-didot: "Montserrat";

@if $project == db {
  $font-didot-medium-ital: "Didot-Medium-Ital";
  $font-didot-medium: "Didot-Medium";
  $font-didot-light-ital: "Didot-Light-Ital";
  $font-didot-light: "Didot-Light";
  $font-futura-medium: "Futura-Medium";
  $font-futura-book: "Futura-Book";
  $font-didot: "Didot-Regular";
} @else {
}

@include font-face($font-didot-medium-ital);
@include font-face($font-didot-medium);
@include font-face($font-didot-light-ital);
@include font-face($font-didot-light);
@include font-face($font-futura-medium);
@include font-face($font-futura-book);
@include font-face($font-didot);
