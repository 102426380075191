@import "./colors.scss";
@import "./fonts.scss";

/* <select> styles */
$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);
$gradient: linear-gradient(35deg, red, purple);
$option: #320a28;

select {
  padding-left: 0px;
  font-family: $font-futura-book;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0.05px;
  color: $textStandard;
  border: none;
  padding-bottom: 4px;
  border-bottom: 1px solid $accent;
  cursor: pointer;
  background-color: transparent;
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
}

// select {
//   /* Reset */
//   appearance: none;
//   border: 1;
//   outline: 0;
//   font: inherit;
//   /* Personalize */
//   //   width: 20em;
//   height: 3em;
//   padding: 0 4em 0 1em;
//   //   background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
//   //       no-repeat right 0.8em center / 1.4em,
//   //     linear-gradient(to left, $glass-icon 3em, $glass 3em);
//   color: $grey2;
//   font-family: $font-futura-book;
//   border-radius: 0.25em;
//   border-color: $grey0;
//   cursor: pointer;
//   /* <option> colors */
//   //   option {
//   //     color: inherit;
//   //     background-color: $option;
//   //   }
//   /* Remove focus outline */
//   &:focus {
//     outline: none;
//   }
//   /* Remove IE arrow */
//   &::-ms-expand {
//     display: none;
//   }
// }
